import { createContext, useState } from "react";
import { useQuery } from "react-query";
import { ClientPrincipalData, CurrentUser } from "../api/metaData/types";

interface UserInfoResult {
  currentUser?: CurrentUser;
  isLoading: boolean;
  actingAs?: string; // A set of initials that the current user is acting as. This should not allow the user to edit someone elses data, but merely to view it.
  setActingAs: (actingAs: string | undefined) => void;
}

interface ClientPrincipalResult {
  clientPrincipal?: ClientPrincipalData;
}

export function useCurrentUser(): UserInfoResult {
  const [actingAs, setActingAs] = useState<string>();
  const query = useQuery("me", getUserInfo, {
    retry: false,
    staleTime: Infinity,
  });
  const { isLoading, data } = query;

  if (data) {
    const currentUser = { ...data, initials: getInitials(data.userDetails) };
    return { currentUser, isLoading, setActingAs, actingAs };
  }

  return { currentUser: data, isLoading, setActingAs, actingAs };
}

async function getUserInfo(): Promise<ClientPrincipalData | undefined> {
  try {
    const response = await fetch("/.auth/me");
    const result = (await response.json()) as ClientPrincipalResult;
    return result?.clientPrincipal;
  } catch (e) {
    return undefined;
  }
}

function getInitials(email: string) {
  const initials = email.split("@")[0];
  return initials;
}

const emptyUserInfo: UserInfoResult = {
  isLoading: true,
  setActingAs: () => {},
};

export const UserContext = createContext<UserInfoResult>(emptyUserInfo);

export const UserProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const userInfo = useCurrentUser();
  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
};
