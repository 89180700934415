import {
  FormControl,
  InputLabel,
  MenuItem,
  outlinedInputClasses,
  Select,
  SelectProps,
  styled,
} from "@mui/material";

interface NumberItem {
  value: number;
  displayValue?: string;
}

interface NumberSelectorProps {
  items: NumberItem[];
  label: string;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  fullWidth?: boolean;
}

const NumberSelect = (props: SelectProps<number>) => {
  return <Select {...props} />;
};

export const NumberSelector = ({
  items,
  label,
  value,
  onChange,
  disabled,
  fullWidth,
}: NumberSelectorProps) => {
  return (
    <StyledFormControl
      size="small"
      fullWidth={fullWidth}
      sx={{ minWidth: 120 }}
    >
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        value={value}
        label={label}
        onChange={(e) => onChange(+e.target.value)}
        disabled={disabled}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.displayValue ?? item.value}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

const OneToFiveMenuItems = [
  { value: 1, displayValue: "1" },
  { value: 2, displayValue: "2" },
  { value: 3, displayValue: "3" },
  { value: 4, displayValue: "4" },
  { value: 5, displayValue: "5" },
];
type OneToFiveNumberSelectorProps = Omit<NumberSelectorProps, "items"> & {
  includeEmptyMember?: boolean;
};
export const OneToFiveNumberSelector = ({
  includeEmptyMember,
  ...rest
}: OneToFiveNumberSelectorProps) => {
  const items = includeEmptyMember
    ? [{ value: 0, displayValue: "None" }, ...OneToFiveMenuItems]
    : OneToFiveMenuItems;
  return <NumberSelector {...rest} items={items} />;
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
}));
const StyledSelect = styled(NumberSelect)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(255, 255, 255, 0.8);
  }
`);
