import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
  ResponderProvided,
} from "@hello-pangea/dnd";
import { List } from "@mui/material";
import { DisplaySkill, Skill } from "../../../api";
import { TeamSkillPriorityInput } from "../../../api/skills/saveTeamSkillPriorities";
import { theme } from "../../../theme/Theme";
import { SkillListItem } from "./SkillListItem";

interface DragDropSkillListProps {
  skills: DisplaySkill[];
  reorderSkills: (skills: DisplaySkill[]) => void;
  reorderTeamSkills: (teamSkills: TeamSkillPriorityInput[]) => void;
  editingSkillId: number | null;
  setEditingSkillId: (skillId: number | null) => void;
  filterByTeamId?: number | null;
}

export function DragDropSkillList({
  skills,
  reorderSkills,
  reorderTeamSkills,
  filterByTeamId,
  ...rest
}: DragDropSkillListProps) {
  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    // Skip if dropped outside the list
    if (!result.destination) {
      return;
    }

    // Re-order the team skill links instead, if we're filtering by a team
    if (filterByTeamId) {
      const reorderedTeamSkills = getReorderedTeamSkills(
        skills,
        filterByTeamId,
        result.source.index,
        result.destination.index
      );
      if (reorderedTeamSkills.length === 0) return;
      reorderTeamSkills(reorderedTeamSkills);
      return;
    }

    // Call function that determines which skills must be re-ordered
    const reorderedSkills = getReorderedSkills(
      skills,
      result.source.index,
      result.destination.index
    );

    if (reorderSkills.length === 0) return;
    reorderSkills(reorderedSkills);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <List dense>
              {skills.map((skill, index) => (
                <Draggable
                  key={skill.id}
                  draggableId={skill.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <SkillListItem skill={skill} key={skill.id} {...rest} />
                    </div>
                  )}
                </Draggable>
              ))}
            </List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function getListStyle(isDraggingOver: boolean) {
  return {
    // We may consider increasing the height of the list by one item when dragging over?
    // width: "100%",
  };
}

function getItemStyle(
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties {
  const style: React.CSSProperties = {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // styles we need to apply on draggables
    ...draggableStyle,
  };

  if (isDragging) {
    style.background = theme.palette.secondary.main;
  }

  return style;
}

const maxSortPriority = 2147483647; // Signed int32
function getReorderedSkills(
  list: Skill[],
  startIndex: number,
  endIndex: number
): Skill[] {
  if (startIndex === endIndex) return [];

  // Determine whether the item was moved up or down the list
  const lowerBoundSkillIdx = startIndex > endIndex ? endIndex - 1 : endIndex;
  const upperBoundSkillIdx = startIndex > endIndex ? endIndex : endIndex + 1;
  const lowerBoundSkill = list[lowerBoundSkillIdx];
  const upperBoundSkill = list[upperBoundSkillIdx];
  const lowerBoundPriority = lowerBoundSkill?.sortPriority ?? maxSortPriority;
  const upperBoundPriority = upperBoundSkill?.sortPriority ?? 0;

  const prioritySpan = lowerBoundPriority - upperBoundPriority;
  if (prioritySpan > 1) {
    const newPriority = lowerBoundPriority - Math.floor(prioritySpan / 2);
    const movedSkill = list[startIndex];
    movedSkill.sortPriority = newPriority;
    return [movedSkill];
  }

  // Account for clashes in sort order here. We need to continue traversing through skills until we find
  // a gap big enough to contain all reordered skills. Start by finding the upper and lower bounds (skill indexes)
  // and then spread their sort orders evenly across the span from lower to upper bounds.
  return [];
}

function getReorderedTeamSkills(
  list: Skill[],
  teamId: number,
  startIndex: number,
  endIndex: number
): TeamSkillPriorityInput[] {
  if (startIndex === endIndex) return [];

  // Determine whether the item was moved up or down the list
  const lowerBoundSkillIdx = startIndex > endIndex ? endIndex - 1 : endIndex;
  const upperBoundSkillIdx = startIndex > endIndex ? endIndex : endIndex + 1;
  const lowerBoundSkill = list[lowerBoundSkillIdx];
  const upperBoundSkill = list[upperBoundSkillIdx];
  const lowerBoundPriority =
    lowerBoundSkill?.teamLinks.find((tl) => tl.teamId === teamId)
      ?.sortPriority ??
    lowerBoundSkill?.sortPriority ??
    maxSortPriority;
  const upperBoundPriority =
    upperBoundSkill?.teamLinks.find((tl) => tl.teamId === teamId)
      ?.sortPriority ??
    upperBoundSkill?.sortPriority ??
    0;

  // The "lower bound" has the highest priority number because the list is sorted descending
  const prioritySpan = lowerBoundPriority - upperBoundPriority;
  if (prioritySpan > 1) {
    const newPriority = lowerBoundPriority - Math.floor(prioritySpan / 2);
    const movedSkill = list[startIndex];
    const movedTeamSkill = movedSkill.teamLinks.find(
      (tl) => tl.teamId === teamId
    );
    if (!movedTeamSkill) return [];
    return [
      {
        teamId: movedTeamSkill.teamId,
        skillId: movedSkill.id,
        sortPriority: newPriority,
      },
    ];
  }

  // Account for clashes in sort order here. We need to continue traversing through skills until we find
  // a gap big enough to contain all reordered skills. Start by finding the upper and lower bounds (skill indexes)
  // and then spread their sort orders evenly across the span from lower to upper bounds.
  return [];
}
