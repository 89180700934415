import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../auth/useCurrentUser";
import { routerPaths } from "./routes/routerPaths";

const Home = () => {
  const { currentUser, isLoading } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    // Navigate to my skills if the user is signed in
    if (currentUser) {
      navigate(routerPaths.mySkills);
    }

    // Navigate to sign-in if the user is not signed in
    navigate(routerPaths.signIn);
  }, [currentUser, isLoading, navigate]);

  return <div>Authenticating...</div>;
};

export default Home;
