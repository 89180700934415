import React from "react";
import { Footer } from "./Footer";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="xl">
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box flex="1 0 auto" display="flex" flexDirection="column">
          {children}
        </Box>
        <Footer />
      </Box>
    </Container>
  );
};
