import { Box, Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { Skill, Team, useMetaData } from "../api";
import { emptySkill, SkillEditor } from "../components/admin";
import { TeamSelector } from "../components/input/TeamSelector";
import { DragDropSkillList } from "../components/skills/SkillList";

const TeamAdministration = () => {
  const {
    displaySkills: skills,
    reorderSkills,
    reorderTeamSkills,
    teams,
  } = useMetaData();
  const [team, setTeam] = useState<Team | null>(null);
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

  const selectableSkills =
    team === null
      ? skills
      : skills
          .filter(
            (s) =>
              team === null ||
              s.teamLinks.map((tl) => tl.teamId).includes(team.id)
          )
          .sort((a, b) => {
            const aPrio =
              a.teamLinks.find((tl) => tl.teamId === team.id)?.sortPriority ??
              a.sortPriority;
            const bPrio =
              b.teamLinks.find((tl) => tl.teamId === team.id)?.sortPriority ??
              b.sortPriority;
            return aPrio > bPrio ? -1 : 1;
          });

  const newEmptySkill: Skill = team
    ? {
        ...emptySkill,
        teamLinks: [{ teamId: team.id, sortPriority: null }],
      }
    : emptySkill;

  return (
    <Box flex="1 1 auto">
      <Typography variant="h1">Team skill administration</Typography>
      <Paper elevation={10}>
        <Box mb={2} width="100%" display="flex" justifyContent="space-between">
          <Box p={2} flex="1 1 auto">
            <TeamSelector
              teams={teams}
              selectedTeam={team}
              onChange={(team) => {
                if (
                  selectedSkill &&
                  team &&
                  !selectedSkill.teamLinks
                    .map((tl) => tl.teamId)
                    .includes(team.id)
                ) {
                  setSelectedSkill(null);
                }

                setTeam(team);
              }}
            />
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              onClick={() => setSelectedSkill(newEmptySkill)}
            >
              Create new skill
            </Button>
          </Box>
        </Box>
      </Paper>
      {selectedSkill && (
        <Paper elevation={10}>
          <Box mb={2}>
            <SkillEditor
              selectedSkill={selectedSkill}
              clearSelectedSkill={() => setSelectedSkill(null)}
              options={{}}
            />
          </Box>
        </Paper>
      )}
      <Paper elevation={10}>
        <DragDropSkillList
          skills={selectableSkills}
          reorderSkills={reorderSkills}
          reorderTeamSkills={reorderTeamSkills}
          editingSkillId={selectedSkill?.id ?? null}
          setEditingSkillId={(skillId: number | null) => {
            const skill = skills.find((s) => s.id === skillId);
            setSelectedSkill(skill ?? null);
          }}
          filterByTeamId={team?.id}
        />
      </Paper>
    </Box>
  );
};

export default TeamAdministration;
