import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouteRenderer } from "./pages/routes";
import { MainMenu } from "./layout/Menu";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import { Layout } from "./layout";
import { UserProvider } from "./auth/useCurrentUser";
import { MetaDataProvider } from "./api/metaData/MetaDataContext";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <MetaDataProvider>
            <Router>
              <Layout>
                <MainMenu />
                <RouteRenderer />
              </Layout>
            </Router>
          </MetaDataProvider>
        </UserProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
