import { getApiBase } from "../getApiBase";

export async function deleteSkill(skillId: number): Promise<boolean> {
  const url = new URL("/api/manage/skill/delete", getApiBase());
  const options = {
    method: "DELETE",
  };

  url.searchParams.append("id", skillId.toString());

  try {
    const response = await fetch(url.href, options);
    if (response.status >= 400) {
      throw await response.text();
    }

    const result = (await response.json()) as boolean;
    return result;
  } catch (e) {
    return false;
  }
}
