import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { EmployeeSearchResult } from "../../../api/employees/types";
import { EmployeeList } from "./EmployeeList";

interface EmployeeOverviewProps {
  employees?: EmployeeSearchResult[];
}

export const EmployeeOverview: React.FunctionComponent<
  EmployeeOverviewProps
> = ({ employees }) => {
  return (
    <Paper elevation={10}>
      <Box p={2} my={2}>
        {employees === undefined ? (
          <Typography variant="h6">
            Select skills(s) and minimum level to search for employees
          </Typography>
        ) : employees.length === 0 ? (
          <Typography variant="h6">
            No employee possesses the wisdom you require
          </Typography>
        ) : (
          <EmployeeList employees={employees} />
        )}
      </Box>
    </Paper>
  );
};
