import { Box, Typography } from "@mui/material";
import doge from "./doge.png";

export const DogeAvatar = () => {
  return (
    <img
      src={doge}
      alt="doge"
      style={{
        width: "64px",
        height: "64px",
        borderRadius: "50%",
      }}
    />
  );
};

interface DogeCommentProps {
  comment: string;
}
export const DogeComment = ({ comment }: DogeCommentProps) => {
  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Box mr={2}>
        <DogeAvatar />
      </Box>
      <Typography variant="body1">{comment}</Typography>
    </Box>
  );
};
