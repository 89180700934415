import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { routerPaths } from "../pages/routes/routerPaths";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { UserContext } from "../auth/useCurrentUser";
import {
  administratorRoleName,
  teamAdministratorRoleName,
} from "../auth/roles";

const logoutUrl = `/.auth/logout?post_logout_redirect_uri=${routerPaths.logout}`;

export const MainMenu = () => {
  const { currentUser } = useContext(UserContext);
  const isAdmin = currentUser?.userRoles.includes(administratorRoleName);
  const isTeamAdmin = currentUser?.userRoles.includes(
    teamAdministratorRoleName
  );
  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        {currentUser && (
          <Box
            display="flex"
            justifyContent="space-between"
            minWidth="852px"
            alignItems="center"
          >
            <Box display="flex" justifyContent="flex-start">
              <MenuItem name="My skills" path={routerPaths.mySkills} />
              <MenuItem
                name="Search employees"
                path={routerPaths.searchEmployees}
              />
              {isAdmin && (
                <MenuItem name="Admin" path={routerPaths.administration} />
              )}
              {isTeamAdmin && (
                <MenuItem name="Team admin" path={routerPaths.teamAdmin} />
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box mx={2} my="auto">
                <Typography>Logged in as {currentUser.userDetails}</Typography>
              </Box>
              <Box mx={2}>
                <Button href={logoutUrl}>Logout</Button>
              </Box>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

interface MenuItemProps {
  name: string;
  path: string;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({ name, path }) => {
  const activeStyle = {
    textDecoration: "underline",
    color: "#bea069",
  };

  const inactiveStyle = {
    textDecoration: "none",
    color: "#fff",
  };
  return (
    <Box mx={2}>
      <NavLink
        to={path}
        style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
      >
        {name}
      </NavLink>
    </Box>
  );
};
