import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { Team } from "../../api";

interface TeamsSelectorProps {
  teams: Team[];
  selectedTeams: Team[];
  onChange: (teams: Team[]) => void;
  label?: string;
}

export const TeamsSelector = ({
  teams,
  selectedTeams,
  onChange,
  label,
}: TeamsSelectorProps) => {
  const options = useMemo(
    () => teams.sort((a, b) => a.name.localeCompare(b.name)),
    [teams]
  );
  return (
    <Autocomplete
      multiple
      id="team-filter-input"
      options={options}
      size="small"
      getOptionLabel={(team) => team.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      value={selectedTeams}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Filter teams"} />
      )}
    />
  );
};

interface TeamSelectorProps {
  teams: Team[];
  selectedTeam: Team | null;
  onChange: (team: Team | null) => void;
  label?: string;
}

export const TeamSelector = ({
  teams,
  selectedTeam,
  onChange,
  label,
}: TeamSelectorProps) => {
  return (
    <Autocomplete
      id="team-select-input"
      options={teams}
      size="small"
      getOptionLabel={(s) => s.name}
      onChange={(_, value) => onChange(value)}
      value={selectedTeam}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Select team"} />
      )}
    />
  );
};
