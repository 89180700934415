import { Autocomplete, TextField } from "@mui/material";
import { Skill } from "../../api";

interface SkillsSelectorProps {
  skills: Skill[];
  selectedSkills: Skill[];
  onChange: (skills: Skill[]) => void;
  label?: string;
}

export const SkillsSelector = ({
  skills,
  selectedSkills,
  onChange,
  label,
}: SkillsSelectorProps) => {
  return (
    <Autocomplete
      multiple
      id="skill-filter-input"
      options={skills}
      size="small"
      getOptionLabel={(s) => s.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={selectedSkills}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Search skills"} />
      )}
    />
  );
};

interface SkillSelectorProps {
  skills: Skill[];
  selectedSkill: Skill | null;
  onChange: (skill: Skill | null) => void;
  label?: string;
}

export const SkillSelector = ({
  skills,
  selectedSkill,
  onChange,
  label,
}: SkillSelectorProps) => {
  return (
    <Autocomplete
      id="skill-select-input"
      options={skills}
      size="small"
      getOptionLabel={(s) => s.name}
      onChange={(_, value) => onChange(value)}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={selectedSkill}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Select skill"} />
      )}
    />
  );
};
