import SchoolIcon from "@mui/icons-material/School";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { DisplaySkill } from "../../../api";

export interface SkillListItemProps {
  skill: DisplaySkill;
  editingSkillId?: number | null; // Used for determining whether this item is currently being edited
  setEditingSkillId: (skillId: number | null) => void;
}

export const SkillListItem = ({
  skill,
  setEditingSkillId,
  editingSkillId,
}: SkillListItemProps) => {
  const isEditing = editingSkillId === skill.id;
  const labelId = `skill-list-label-${skill.id}`;

  return (
    <ListItem key={labelId} disablePadding>
      <ListItemButton
        onClick={() => setEditingSkillId(skill.id)}
        selected={isEditing}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flex="1 1 75%">
            <ListItemAvatar>
              <Box sx={{ position: "relative" }}>
                <SchoolIcon
                  color={isEditing ? "secondary" : "disabled"}
                  sx={{ position: "absolute" }}
                />
                {skill.isPromoted && (
                  <LocalParkingIcon
                    color="secondary"
                    sx={{
                      position: "absolute",
                      left: "12px",
                      top: "-4px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                )}
                {skill.isLegacy && (
                  <AutoDeleteIcon
                    color="primary"
                    sx={{
                      position: "absolute",
                      left: "-8px",
                      top: "12px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                )}
              </Box>
            </ListItemAvatar>
            <ListItemText id={labelId}>{skill.name}</ListItemText>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
