import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { Skill, useMetaData } from "../api";
import { SkillEditor, SkillInputSelector } from "../components/admin";

const Administration = () => {
  const metaData = useMetaData();
  const skills = metaData.displaySkills;
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

  return (
    <Box flex="1 1 auto">
      <Typography variant="h1">Skill administration</Typography>
      <Paper elevation={10}>
        <SkillInputSelector
          selectedSkill={selectedSkill}
          setSelectedSkill={setSelectedSkill}
          skills={skills}
        />
      </Paper>
      <Paper elevation={10}>
        <SkillEditor
          selectedSkill={selectedSkill}
          clearSelectedSkill={() => setSelectedSkill(null)}
          options={{ canPromote: true }}
        />
      </Paper>
    </Box>
  );
};

export default Administration;
