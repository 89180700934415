import { Route, Routes } from "react-router-dom";
import Home from "..";
import AccessDenied from "../access-denied";
import Administration from "../administration";
import Logout from "../logout";
import MySkills from "../my-skills";
import PageNotFound from "../page-not-found-404";
import SearchEmployees from "../search-employees";
import SignIn from "../sign-in";
import TeamAdministration from "../team-admin";
import { routerPaths } from "./routerPaths";

export function RouteRenderer() {
  return (
    <Routes>
      <Route path={routerPaths.mySkills} element={<MySkills />} />
      <Route path={routerPaths.searchEmployees} element={<SearchEmployees />} />
      <Route path={routerPaths.accessDenied} element={<AccessDenied />} />
      <Route path={routerPaths.signIn} element={<SignIn />} />
      <Route path={routerPaths.administration} element={<Administration />} />
      <Route path={routerPaths.teamAdmin} element={<TeamAdministration />} />
      <Route path={routerPaths.custom404} element={<PageNotFound />} />
      <Route path={routerPaths.logout} element={<Logout />} />
      <Route path={routerPaths.home} element={<Home />} />
    </Routes>
  );
}
