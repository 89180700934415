import { MetaDataContainer } from "./types";
import { getApiBase } from "../getApiBase";

export async function getMetaData(): Promise<MetaDataContainer | undefined> {
  const url = new URL("/api/metaData", getApiBase());
  try {
    const response = await fetch(url.href);
    const result = (await response.json()) as MetaDataContainer;
    return result;
  } catch (e) {
    return undefined;
  }
}
