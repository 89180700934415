import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { DogeComment } from "./DogeAvatar";
import { UserAction } from "./types";

interface CommentBoxProps {
  lastAction: UserAction;
  showDoge?: boolean;
}

export const CommentBox = ({ lastAction, showDoge }: CommentBoxProps) => {
  const text = lastAction.type === "Save" ? "Saved!" : "Removed!";
  const comment = useMemo(() => getComment(), []);
  return (
    <Box
      width="100%"
      minHeight={200}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6">{text}</Typography>
        {showDoge && <DogeComment comment={comment} />}
      </Box>
    </Box>
  );
};

function getComment() {
  var randomCommentIndex = Math.floor(Math.random() * comments.length);
  return comments[randomCommentIndex];
}

const comments = [
  "Such skill!",
  "Much impressive!",
  "To the moon!",
  "Much wow!",
  "Very knowledge!",
  "Many intelligence!",
  "Good boy!",
  "You 💎!",
  "⭐⭐⭐⭐⭐",
];
