import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { Platform } from "../../api";

interface PlatformsSelectorProps {
  platforms: Platform[];
  selectedPlatforms: Platform[];
  onChange: (platforms: Platform[]) => void;
  label?: string;
}

export const PlatformsSelector = ({
  platforms,
  selectedPlatforms,
  onChange,
  label,
}: PlatformsSelectorProps) => {
  const options = useMemo(
    () => platforms.sort((a, b) => a.name.localeCompare(b.name)),
    [platforms]
  );
  return (
    <Autocomplete
      multiple
      id="platform-filter-input"
      options={options}
      size="small"
      getOptionLabel={(p) => p.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      value={selectedPlatforms}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Filter platforms"} />
      )}
    />
  );
};
