import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { Tool } from "../../api";

interface ToolsSelectorProps {
  tools: Tool[];
  selectedTools: Tool[];
  onChange: (tools: Tool[]) => void;
  label?: string;
}

export const ToolsSelector = ({
  tools,
  selectedTools,
  onChange,
  label,
}: ToolsSelectorProps) => {
  const options = useMemo(
    () => tools.sort((a, b) => a.name.localeCompare(b.name)),
    [tools]
  );
  return (
    <Autocomplete
      multiple
      id="tool-filter-input"
      options={options}
      size="small"
      getOptionLabel={(t) => t.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      value={selectedTools}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Filter tools"} />
      )}
    />
  );
};
