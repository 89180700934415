import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { SkillArea } from "../../api";

interface SkillAreasSelectorProps {
  skillAreas: SkillArea[];
  selectedSkillAreas: SkillArea[];
  onChange: (skillAreas: SkillArea[]) => void;
}

export const SkillAreasSelector = ({
  skillAreas,
  selectedSkillAreas,
  onChange,
}: SkillAreasSelectorProps) => {
  const options = useMemo(
    () => skillAreas.sort((a, b) => a.name.localeCompare(b.name)),
    [skillAreas]
  );
  return (
    <Autocomplete
      multiple
      id="skill-area-filter-input"
      options={options}
      size="small"
      getOptionLabel={(s) => s.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      value={selectedSkillAreas}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Filter skill areas" />
      )}
    />
  );
};

interface SkillAreaSelectorProps {
  skillAreas: SkillArea[];
  selectedSkillArea: SkillArea | null;
  onChange: (skillArea: SkillArea | null) => void;
}

export const SkillAreaSelector = ({
  skillAreas,
  selectedSkillArea,
  onChange,
}: SkillAreaSelectorProps) => {
  return (
    <Autocomplete
      id="skill-area-select-input"
      options={skillAreas}
      size="small"
      getOptionLabel={(s) => s.name}
      onChange={(_, value) => onChange(value)}
      value={selectedSkillArea}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Select skill area" />
      )}
    />
  );
};
