import { EmployeeSkill, GetEmployeeSkillsInput } from ".";
import { getApiBase } from "../getApiBase";

export async function getEmployeeSkills(
  input: GetEmployeeSkillsInput
): Promise<EmployeeSkill[] | undefined> {
  const url = new URL("/api/employeeSkills", getApiBase());
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(input),
  };

  try {
    const response = await fetch(url.href, options);
    if (response.status >= 400) {
      throw await response.text();
    }

    const result = (await response.json()) as EmployeeSkill[];
    return result;
  } catch (e) {
    return undefined;
  }
}
