import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { getRelativeSignInUrl } from "../auth/authenticationUrls";
import { UserContext } from "../auth/useCurrentUser";
import { routerPaths } from "./routes/routerPaths";
import { Avatar, Button } from "@mui/material";

const loginReturnUrl = routerPaths.mySkills;

const SignIn = () => {
  const { isLoading, currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (currentUser) {
      navigate(loginReturnUrl);
      return;
    }
  }, [currentUser, isLoading, navigate]);

  return (
    <Box
      flex="1 0 auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Button variant="contained" href={getRelativeSignInUrl()}>
        Sign in
      </Button>
    </Box>
  );
};

export default SignIn;
