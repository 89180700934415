import SchoolIcon from "@mui/icons-material/School";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { DisplaySkill, EmployeeSkill } from "../../../api";

export interface EmployeeSkillListItemProps {
  skill: DisplaySkill;
  employeeSkill?: EmployeeSkill;
  editingSkillId?: number; // Used for determining whether this item is currently being edited
  setEditingSkillId: (skillId: number | undefined) => void;
}

export const EmployeeSkillListItem = ({
  skill,
  employeeSkill,
  setEditingSkillId,
  editingSkillId,
}: EmployeeSkillListItemProps) => {
  const isEditing = editingSkillId === skill.id;
  const labelId = `skill-list-label-${skill.id}`;

  return (
    <ListItem key={labelId} disablePadding>
      <ListItemButton
        onClick={() => setEditingSkillId(skill.id)}
        selected={isEditing}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flex="1 1 75%">
            <ListItemAvatar>
              <Box sx={{ position: "relative" }}>
                <SchoolIcon
                  color={
                    isEditing
                      ? "secondary"
                      : employeeSkill
                      ? "primary"
                      : "disabled"
                  }
                  sx={{ position: "absolute" }}
                />
                {skill.isPromoted && (
                  <LocalParkingIcon
                    color="secondary"
                    sx={{
                      position: "absolute",
                      left: "12px",
                      top: "-4px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                )}
                {skill.isLegacy && (
                  <AutoDeleteIcon
                    color="primary"
                    sx={{
                      position: "absolute",
                      left: "-8px",
                      top: "12px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                )}
              </Box>
            </ListItemAvatar>
            <ListItemText id={labelId}>{skill.name}</ListItemText>
          </Box>
          {employeeSkill && (
            <Box display="flex" justifyContent="flex-end" flex="0 1 10%">
              <Box display="flex" alignItems="center" mr={1}>
                <Typography ml={1}>
                  {employeeSkill.currentLevel ?? 0}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <ArrowForwardIcon />
                <Typography ml={1}>
                  {employeeSkill.plannedLevel
                    ? employeeSkill.plannedLevel
                    : "?"}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};
