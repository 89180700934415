import { List } from "@mui/material";
import { DisplaySkill, EmployeeSkill } from "../../../api";
import { EmployeeSkillListItem } from "./EmployeeSkillListItem";

interface EmployeeSkillListProps {
  skills: DisplaySkill[];
  employeeSkills: EmployeeSkill[];
  editingSkillId?: number;
  setEditingSkillId: (skillId?: number) => void;
  currentLevel?: number;
  plannedLevel?: number;
  setCurrentLevel: (level?: number) => void;
  setPlannedLevel: (level?: number) => void;
}
export const EmployeeSkillList = ({
  skills,
  employeeSkills,
  ...rest
}: EmployeeSkillListProps) => {
  return (
    <List dense>
      {skills.map((s) => {
        const employeeSkill = employeeSkills.find((e) => e.skillId === s.id);
        return (
          <EmployeeSkillListItem
            skill={s}
            employeeSkill={employeeSkill}
            key={s.id}
            {...rest}
          />
        );
      })}
    </List>
  );
};
