import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { DisplaySkill, Skill } from "../../../api";
import { searchEmployees } from "../../../api/employees";
import {
  EmployeeSearchResult,
  EmployeeSkillSearchInput,
} from "../../../api/employees/types";
import { SkillsSelector } from "../../input";
import { OneToFiveNumberSelector } from "../../input";

interface EmployeeSearchProps {
  skills: DisplaySkill[];
  setEmployees: (employees?: EmployeeSearchResult[]) => void;
}

export const EmployeeSearch: React.FunctionComponent<EmployeeSearchProps> = ({
  skills,
  setEmployees,
}) => {
  // Keep track of the selected skills
  const [skillsFilter, setSkillsFilter] = useState<Skill[]>([]);

  // Keep state for the minimum level
  const [minimumLevel, setMinimumLevel] = useState<number>();

  const handleSearch = async () => {
    if (skillsFilter.length === 0) return;
    const skillsInput = skillsFilter.map((s) => {
      const skillInput: EmployeeSkillSearchInput = {
        skillId: s.id,
        minimumLevel: minimumLevel ?? 0,
      };
      return skillInput;
    });
    const employees = await searchEmployees({ skills: skillsInput });
    setEmployees(employees);
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={2} my={2}>
      <Box flex="1 1 70%">
        <Paper elevation={10}>
          <Box p={2}>
            {skills.length === 0 ? (
              <Typography variant="h6">
                No skills match the selected filters
              </Typography>
            ) : (
              <SkillsSelector
                skills={skills}
                selectedSkills={skillsFilter}
                onChange={setSkillsFilter}
                label="Select skills"
              />
            )}
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 30%" position="relative">
        <Box position="sticky" top={0} left={0} height="10%">
          <Paper elevation={10}>
            <Box p={2} display="flex" justifyContent="space-between">
              <OneToFiveNumberSelector
                label="Minimum level"
                value={minimumLevel ?? 0}
                onChange={setMinimumLevel}
                includeEmptyMember
              />
              <Button variant="contained" onClick={handleSearch}>
                Search
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
