import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../auth/useCurrentUser";
import { saveSkillPriorities, saveTeamSkillPriorities } from "../skills";
import { TeamSkillPriorityInput } from "../skills/saveTeamSkillPriorities";
import { getMetaData } from "./getMetaData";
import {
  Discipline,
  DisplaySkill,
  ExtendedMetaDataContainer,
  MetaDataContainer,
  Platform,
  Skill,
  SkillArea,
  Team,
  Tool,
} from "./types";

export const metaDataQueryKey = "metaData";
const emptyMetaData: ExtendedMetaDataContainer = {
  skills: [],
  disciplines: [],
  platforms: [],
  teams: [],
  tools: [],
  displaySkills: [],
  skillAreas: [],
  employees: [],
  reorderSkills: (_: Skill[]) => {
    // no op
  },
  reorderTeamSkills: (_: TeamSkillPriorityInput[]) => {
    // no op
  },
};
export const MetaDataContext =
  createContext<ExtendedMetaDataContainer>(emptyMetaData);

export const MetaDataProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { currentUser } = useContext(UserContext);
  const query = useQuery(
    [metaDataQueryKey, !!currentUser],
    () => (currentUser ? getMetaData() : undefined),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );
  const [metaData, setMetaData] = useState<MetaDataContainer>();
  useEffect(
    function updateMetaData() {
      setMetaData(query.data);
    },
    [query.data]
  );
  const displaySkills = metaData?.skills
    .map((s) =>
      mapToDisplaySkill(
        s,
        metaData.teams,
        metaData.disciplines,
        metaData.platforms,
        metaData.tools,
        metaData.skillAreas
      )
    )
    .sort((a, b) => (a.sortPriority > b.sortPriority ? -1 : 1));

  // Function for reordering skills locally in context state and
  // externally on the server
  async function reorderSkills(reorderedSkills: Skill[]) {
    updateSkills(reorderedSkills);
    const updateInput = reorderedSkills.map((s) => ({
      id: s.id,
      sortPriority: s.sortPriority,
    }));
    const updatedSkills = await saveSkillPriorities(updateInput);
    updateSkills(updatedSkills);
  }

  async function reorderTeamSkills(teamSkills: TeamSkillPriorityInput[]) {
    if (!metaData?.skills) return;

    const skillsToUpdate = metaData.skills.filter((s) =>
      teamSkills.map((ts) => ts.skillId).includes(s.id)
    );
    const updatedSkills: Skill[] = skillsToUpdate.map((s) => {
      const teamSkill = teamSkills.find((ts) => ts.skillId === s.id);
      return {
        ...s,
        teamLinks: [
          ...s.teamLinks.filter(
            (tl) => !teamSkills.map((ts) => ts.teamId).includes(tl.teamId)
          ),
          {
            teamId: teamSkill?.teamId ?? 0,
            sortPriority: teamSkill?.sortPriority ?? null,
          },
        ],
      };
    });
    updateSkills(updatedSkills);
    const updatedSkillsFromServer = await saveTeamSkillPriorities(teamSkills);
    updateSkills(updatedSkillsFromServer);
  }

  function updateSkills(updatedSkills: Skill[]) {
    if (updatedSkills.length === 0) return;
    setMetaData((d) => {
      if (!d) return;
      const reorderedSkillList = [
        ...d?.skills.filter(
          (s) => !updatedSkills.map((rs) => rs.id).includes(s.id)
        ),
        ...updatedSkills,
      ];
      return { ...d, skills: reorderedSkillList };
    });
  }

  const extendedMetaData =
    metaData && displaySkills
      ? {
          ...emptyMetaData,
          ...metaData,
          displaySkills,
          reorderSkills,
          reorderTeamSkills,
        }
      : emptyMetaData;

  return (
    <MetaDataContext.Provider value={extendedMetaData}>
      {children}
    </MetaDataContext.Provider>
  );
};

function mapToDisplaySkill(
  skill: Skill,
  teams: Team[],
  disciplines: Discipline[],
  platforms: Platform[],
  tools: Tool[],
  skillAreas: SkillArea[]
): DisplaySkill {
  const teamNames = teams
    .filter((t) => skill.teamLinks.map((t) => t.teamId).includes(t.id))
    .map((t) => t.name)
    .join(", ");
  const disciplineNames = disciplines
    .filter((d) => skill.disciplineIds.includes(d.id))
    .map((d) => d.name)
    .join(", ");
  const platformNames = platforms
    .filter((p) => skill.platformIds.includes(p.id))
    .map((p) => p.name)
    .join(", ");
  const toolNames = tools
    .filter((t) => skill.toolIds.includes(t.id))
    .map((t) => t.name)
    .join(", ");
  const skillAreaName = skillAreas.find(
    (s) => s.id === skill.skillAreaId
  )?.name;
  return {
    ...skill,
    teamNames,
    disciplineNames,
    platformNames,
    toolNames,
    skillAreaName,
  };
}
