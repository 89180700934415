import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Employee,
  EmployeeSearchResult,
  EmployeeSkillWithName,
} from "../../../api/employees/types";
import { EmployeeSkill } from "../../../api";
import { useState } from "react";

export interface EmployeeListItemProps {
  employee: EmployeeSearchResult;
}

export const EmployeeListItem = ({ employee }: EmployeeListItemProps) => {
  return employee.skills.length < 2 ? (
    <SingleSkillListItem employee={employee} skill={employee.skills[0]} />
  ) : (
    <MultiSkillListItems employee={employee} />
  );
};

interface SingleSkillListItemProps {
  employee: Employee;
  skill: EmployeeSkill;
}
const SingleSkillListItem = ({ employee, skill }: SingleSkillListItemProps) => {
  const nameLabelId = `employee-name-label-${employee.name}`;
  const teamLabelId = `employee-team-label-${employee.teamName}`;
  const emailLabelId = `employee-email-label-${employee.email}`;
  const phoneLabelId = `employee-phone-label-${employee.phone}`;
  return (
    <ListItem key={employee.initials} disablePadding>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flex="1 1 30%">
          <Box my="auto" mr={4}>
            <PersonIcon color="secondary" />
          </Box>
          <ListItemText id={nameLabelId}>{employee.name}</ListItemText>
        </Box>
        <Box flex="1 1 20%" display="flex">
          <Box mr={1}>
            <GroupsIcon />
          </Box>
          <ListItemText id={teamLabelId}>
            {employee.teamName ? employee.teamName : "No team"}
          </ListItemText>
        </Box>
        <Box flex="0 0 15%" display="flex">
          <Box mr={1}>
            <EmailIcon />
          </Box>
          <ListItemText id={emailLabelId}>{employee.email}</ListItemText>
        </Box>
        <Box flex="0 0 15%" display="flex">
          <Box mr={1}>
            <PhoneIcon />
          </Box>
          <ListItemText id={phoneLabelId}>{employee.phone}</ListItemText>
        </Box>
        <Box flex="0 0 10%" display="flex">
          <Box display="flex" justifyContent="flex-start" flex="0 1 10%">
            <Box display="flex" alignItems="center" mr={1}>
              <Box mr={1}>
                <SchoolIcon />
              </Box>
              <Typography ml={1}>{skill.currentLevel ?? 0}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <ArrowForwardIcon />
              <Typography ml={1}>
                {skill.plannedLevel ? skill.plannedLevel : "?"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

interface MultiSkillListItemsProps {
  employee: EmployeeSearchResult;
}
const MultiSkillListItems = ({ employee }: MultiSkillListItemsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const nameLabelId = `employee-name-label-${employee.name}`;
  const teamLabelId = `employee-team-label-${employee.teamName}`;
  const emailLabelId = `employee-email-label-${employee.email}`;
  const phoneLabelId = `employee-phone-label-${employee.phone}`;
  return (
    <>
      <ListItemButton
        key={employee.initials}
        onClick={() => setIsOpen((v) => !v)}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" flex="1 1 30%">
            <Box my="auto" mr={4}>
              <PersonIcon color="secondary" />
            </Box>
            <ListItemText id={nameLabelId}>{employee.name}</ListItemText>
          </Box>
          <Box flex="1 1 20%" display="flex">
            <Box mr={1}>
              <GroupsIcon />
            </Box>
            <ListItemText id={teamLabelId}>
              {employee.teamName ? employee.teamName : "No team"}
            </ListItemText>
          </Box>
          <Box flex="0 0 15%" display="flex">
            <Box mr={1}>
              <EmailIcon />
            </Box>
            <ListItemText id={emailLabelId}>{employee.email}</ListItemText>
          </Box>
          <Box flex="0 0 15%" display="flex">
            <Box mr={1}>
              <PhoneIcon />
            </Box>
            <ListItemText id={phoneLabelId}>{employee.phone}</ListItemText>
          </Box>
          <Box flex="0 0 10%" display="flex" justifyContent="flex-end">
            <Box mr={1}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
          </Box>
        </Box>
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {employee.skills.map((skill) => {
            return <EmployeeSkillListItem skill={skill} />;
          })}
        </List>
      </Collapse>
    </>
  );
};

interface EmployeeSkillListItemProps {
  skill: EmployeeSkillWithName;
}
const EmployeeSkillListItem = ({ skill }: EmployeeSkillListItemProps) => {
  return (
    <ListItem disablePadding>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flex="1 1 75%" pl={4}>
          <Box my="auto" mr={4}>
            <SchoolIcon color="primary" />
          </Box>
          <ListItemText>{skill.skillName}</ListItemText>
        </Box>
        <Box display="flex" justifyContent="flex-start" flex="0 1 10%">
          <Box display="flex" alignItems="center" mr={1}>
            <Typography ml={1}>{skill.currentLevel ?? 0}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <ArrowForwardIcon />
            <Typography ml={1}>
              {skill.plannedLevel ? skill.plannedLevel : "?"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};
