import { useMetaData } from "../api/metaData/useMetaData";
import { useEmployeeSkills } from "../components/skills/useEmployeeSkills";
import {
  Discipline,
  EmployeeSkill,
  Platform,
  saveEmployeeSkill,
  Skill,
  SkillArea,
  Team,
  Tool,
} from "../api";
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import {
  DisciplinesSelector,
  PlatformsSelector,
  SkillAreasSelector,
  SkillsSelector,
  ToolsSelector,
  TeamsSelector,
  EmployeeSelector,
  OneToFiveNumberSelector,
} from "../components/input";
import { deleteEmployeeSkill } from "../api/employeeSkills/deleteEmployeeSkill";
import { UserContext } from "../auth/useCurrentUser";
import { EmployeeSkillOverview } from "../components/skills/EmployeeSkillOverview/EmployeeSkillOverview";
import { ProtectedRoute } from "../auth/ProtectedRoute";

const MySkills = () => {
  return (
    <ProtectedRoute>
      <MySkillsContent />
    </ProtectedRoute>
  );
};

const MySkillsContent = () => {
  const metaData = useMetaData();
  const { currentUser, isLoading, actingAs, setActingAs } =
    useContext(UserContext);
  const { currentSkills, setSkill, removeSkill } = useEmployeeSkills(actingAs);
  const [teamsFilter, setTeamsFilter] = useState<Team[]>([]);
  const [toolsFilter, setToolsFilter] = useState<Tool[]>([]);
  const [disciplinesFilter, setDisciplinesFilter] = useState<Discipline[]>([]);
  const [platformsFilter, setPlatformsFilter] = useState<Platform[]>([]);
  const [skillAreasFilter, setSkillAreasFilter] = useState<SkillArea[]>([]);
  const [skillsFilter, setSkillsFilter] = useState<Skill[]>([]);
  const [showMySkillsOnly, setShowMySkillsOnly] = useState<boolean>(false);
  const [showPromotedSkillsOnly, setShowPromotedSkillsOnly] =
    useState<boolean>(false);
  const [showLegacySkills, SetShowLegacySkills] = useState<boolean>(false);
  const [showFocusSkillsOnly, SetShowFocusSkillsOnly] =
    useState<boolean>(false);
  const [minimumSkillLevel, setMinimumSkillLevel] = useState<number | null>(
    null
  );

  if (isLoading || !currentUser) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  let filteredSkills = metaData.displaySkills.filter((skill) => {
    return (
      (teamsFilter.length === 0 ||
        skill.teamLinks.some((tl) =>
          teamsFilter.some((team) => team.id === tl.teamId)
        )) &&
      (toolsFilter.length === 0 ||
        skill.toolIds.some((id) =>
          toolsFilter.some((tool) => tool.id === id)
        )) &&
      (disciplinesFilter.length === 0 ||
        skill.disciplineIds.some((id) =>
          disciplinesFilter.some((discipline) => discipline.id === id)
        )) &&
      (platformsFilter.length === 0 ||
        skill.platformIds.some((id) =>
          platformsFilter.some((platform) => platform.id === id)
        )) &&
      (skillAreasFilter.length === 0 ||
        skillAreasFilter.some(
          (skillArea) => skill.skillAreaId === skillArea.id
        )) &&
      (skillsFilter.length === 0 ||
        skillsFilter.some((s) => s.id === skill.id)) &&
      (!showMySkillsOnly ||
        currentSkills.some((s) => s.skillId === skill.id)) &&
      (!showFocusSkillsOnly ||
        currentSkills.some(
          (s) =>
            s.skillId === skill.id &&
            s.plannedLevel &&
            s.plannedLevel > s.currentLevel
        )) &&
      (!showPromotedSkillsOnly || skill.isPromoted) &&
      (showLegacySkills || !skill.isLegacy) &&
      (!minimumSkillLevel ||
        currentSkills.some(
          (s) => s.skillId === skill.id && s.currentLevel >= minimumSkillLevel
        ))
    );
  });

  const setEmployeeSkill = async (skill: EmployeeSkill) => {
    const success = await saveEmployeeSkill(skill);
    if (success) {
      setSkill(skill);
    }
    return success;
  };

  const removeEmployeeSkill = async (skillId: number) => {
    const success = await deleteEmployeeSkill({
      skillId,
      employeeInitials: currentUser.initials,
    });

    if (success) {
      removeSkill(skillId);
    }

    return success;
  };

  var actingAsEmployee =
    metaData.employees.find((e) => e.initials === actingAs) ?? null;

  return (
    <Box flex="1 1 auto">
      <Typography variant="h1">
        {actingAsEmployee ? `${actingAsEmployee.name}'s` : "My"} skills
      </Typography>
      <Paper elevation={10}>
        <Box my={2} p={2}>
          <EmployeeSelector
            employees={metaData.employees.filter(
              (e) => e.initials !== currentUser.initials
            )}
            selectedEmployee={actingAsEmployee}
            onChange={(v) => setActingAs(v?.initials)}
          />
        </Box>
      </Paper>
      <Paper elevation={10}>
        <Box my={2} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Typography color="secondary.main">Category filters</Typography>
              </Divider>
            </Grid>
            <Grid item xs={12} md={4}>
              <TeamsSelector
                teams={metaData.teams}
                selectedTeams={teamsFilter}
                onChange={setTeamsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ToolsSelector
                tools={metaData.tools}
                selectedTools={toolsFilter}
                onChange={setToolsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DisciplinesSelector
                disciplines={metaData.disciplines}
                selectedDisciplines={disciplinesFilter}
                onChange={setDisciplinesFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlatformsSelector
                platforms={metaData.platforms}
                selectedPlatforms={platformsFilter}
                onChange={setPlatformsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SkillAreasSelector
                skillAreas={metaData.skillAreas}
                selectedSkillAreas={skillAreasFilter}
                onChange={setSkillAreasFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Typography color="secondary.main">Other filters</Typography>
              </Divider>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={2}>
                <OneToFiveNumberSelector
                  fullWidth
                  label="Minimum current skill level"
                  value={minimumSkillLevel ?? 0}
                  onChange={setMinimumSkillLevel}
                  includeEmptyMember
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <Box px={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        inputProps={{ "aria-label": "My skills only" }}
                        value={showMySkillsOnly}
                        onChange={(_, checked) => setShowMySkillsOnly(checked)}
                      />
                    }
                    label={
                      actingAsEmployee
                        ? `${actingAsEmployee.name}'s skills only`
                        : "My skills only"
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        inputProps={{
                          "aria-label": "Planned level higher than current",
                        }}
                        value={showFocusSkillsOnly}
                        onChange={(_, checked) =>
                          SetShowFocusSkillsOnly(checked)
                        }
                      />
                    }
                    label="Planned level higher than current"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        inputProps={{ "aria-label": "Promoted skills only" }}
                        value={showPromotedSkillsOnly}
                        onChange={(_, checked) =>
                          setShowPromotedSkillsOnly(checked)
                        }
                      />
                    }
                    label="Promoted skills only"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        inputProps={{ "aria-label": "Show legacy skills" }}
                        value={showLegacySkills}
                        onChange={(_, checked) => SetShowLegacySkills(checked)}
                      />
                    }
                    label="Show legacy skills"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Typography color="secondary.main">Search</Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <SkillsSelector
                skills={metaData.displaySkills}
                selectedSkills={skillsFilter}
                onChange={setSkillsFilter}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <EmployeeSkillOverview
        skills={filteredSkills}
        employeeSkills={currentSkills}
        setEmployeeSkill={setEmployeeSkill}
        removeEmployeeSkill={removeEmployeeSkill}
        employeeInitials={currentUser.initials}
        actingAs={actingAs}
      />
    </Box>
  );
};

export default MySkills;
