import { List } from "@mui/material";
import { EmployeeSearchResult } from "../../../api/employees/types";
import { EmployeeListItem } from "./EmployeeListItem";

interface EmployeeListProps {
  employees: EmployeeSearchResult[];
}

export const EmployeeList: React.FunctionComponent<EmployeeListProps> = ({
  employees,
}) => {
  return (
    <List dense>
      {employees.map((e) => {
        return <EmployeeListItem employee={e} key={e.initials} />;
      })}
    </List>
  );
};
