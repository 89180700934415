import { useMetaData } from "../api/metaData/useMetaData";
import { Discipline, Platform, SkillArea, Team, Tool } from "../api";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useContext, useState } from "react";
import {
  DisciplinesSelector,
  PlatformsSelector,
  SkillAreasSelector,
  ToolsSelector,
  TeamsSelector,
} from "../components/input";
import { UserContext } from "../auth/useCurrentUser";
import { EmployeeSearch } from "../components/skills/EmployeeSearch";
import { EmployeeSearchResult } from "../api/employees/types";
import { EmployeeOverview } from "../components/skills/EmployeeSearch/EmployeeOverview";
import { ProtectedRoute } from "../auth/ProtectedRoute";

const SearchEmployees = () => {
  return (
    <ProtectedRoute>
      <SearchEmployeesContent />
    </ProtectedRoute>
  );
};

const SearchEmployeesContent = () => {
  const metaData = useMetaData();
  const { currentUser, isLoading } = useContext(UserContext);
  const [teamsFilter, setTeamsFilter] = useState<Team[]>([]);
  const [toolsFilter, setToolsFilter] = useState<Tool[]>([]);
  const [disciplinesFilter, setDisciplinesFilter] = useState<Discipline[]>([]);
  const [platformsFilter, setPlatformsFilter] = useState<Platform[]>([]);
  const [skillAreasFilter, setSkillAreasFilter] = useState<SkillArea[]>([]);
  const [employees, setEmployees] = useState<EmployeeSearchResult[]>();

  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (!currentUser) {
    return <Box>Not authorized</Box>; // Should redirect to sign in page...
  }

  const filteredSkills = metaData.displaySkills.filter((skill) => {
    return (
      (teamsFilter.length === 0 ||
        skill.teamLinks.some((tl) =>
          teamsFilter.some((team) => team.id === tl.teamId)
        )) &&
      (toolsFilter.length === 0 ||
        skill.toolIds.some((id) =>
          toolsFilter.some((tool) => tool.id === id)
        )) &&
      (disciplinesFilter.length === 0 ||
        skill.disciplineIds.some((id) =>
          disciplinesFilter.some((discipline) => discipline.id === id)
        )) &&
      (platformsFilter.length === 0 ||
        skill.platformIds.some((id) =>
          platformsFilter.some((platform) => platform.id === id)
        )) &&
      (skillAreasFilter.length === 0 ||
        skillAreasFilter.some(
          (skillArea) => skill.skillAreaId === skillArea.id
        ))
    );
  });

  return (
    <Box flex="1 1 auto">
      <Typography variant="h1">Search employees</Typography>
      <Paper elevation={10}>
        <Box my={2} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TeamsSelector
                teams={metaData.teams}
                selectedTeams={teamsFilter}
                onChange={setTeamsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ToolsSelector
                tools={metaData.tools}
                selectedTools={toolsFilter}
                onChange={setToolsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DisciplinesSelector
                disciplines={metaData.disciplines}
                selectedDisciplines={disciplinesFilter}
                onChange={setDisciplinesFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlatformsSelector
                platforms={metaData.platforms}
                selectedPlatforms={platformsFilter}
                onChange={setPlatformsFilter}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SkillAreasSelector
                skillAreas={metaData.skillAreas}
                selectedSkillAreas={skillAreasFilter}
                onChange={setSkillAreasFilter}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <EmployeeSearch skills={filteredSkills} setEmployees={setEmployees} />
      <EmployeeOverview employees={employees} />
    </Box>
  );
};

export default SearchEmployees;
