import { Autocomplete, TextField } from "@mui/material";
import { Employee } from "../../api";

interface EmployeeSelectorProps {
  employees: Employee[];
  selectedEmployee: Employee | null;
  onChange: (employee: Employee | null) => void;
}

export const EmployeeSelector = ({
  employees,
  selectedEmployee,
  onChange,
}: EmployeeSelectorProps) => {
  return (
    <Autocomplete
      id="employee-filter-input"
      options={employees}
      size="small"
      getOptionLabel={(e) => `${e.name} (${e.initials})`}
      defaultValue={null}
      onChange={(_, value) => onChange(value)}
      value={selectedEmployee}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            selectedEmployee
              ? "Viewing skills for"
              : "Like to pretend you're someone else?"
          }
        />
      )}
    />
  );
};
