import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Divider, Typography } from "@mui/material";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import SkateboardingIcon from "@mui/icons-material/Skateboarding";

interface SkillLevelsDialogProps {
  open: boolean;
  onClose: () => void;
}

export function SkillLevelsDialog({ onClose, open }: SkillLevelsDialogProps) {
  if (!open) return null;
  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle>Skill level definitions</DialogTitle>
      <List>
        <SkillLevelListItem
          icon={<BabyChangingStationIcon />}
          level={1}
          title="Fundamental"
          description="Knows conceptually about the skill area, but has limited practical experience."
        />
        <Divider variant="middle" component="li" />
        <SkillLevelListItem
          icon={<EscalatorWarningIcon />}
          level={2}
          title="Learner"
          description="Some experience: Can take on tasks within this skill area (at clients) but may now and then need assistance."
        />
        <Divider variant="middle" component="li" />
        <SkillLevelListItem
          icon={<DirectionsWalkIcon />}
          level={3}
          title="Independent"
          description="Medium to high experience: Normally self-helped at clients within this skill area; Can in some situations even guide others and/or take part in the leading role at clients."
        />
        <Divider variant="middle" component="li" />
        <SkillLevelListItem
          icon={<DirectionsRunIcon />}
          level={4}
          title="Lead"
          description="High experience: Has completed several projects where this skill area is involved, knows Kapacity's toolbox in and out and is able to take the lead at clients in most situations."
        />
        <Divider variant="middle" component="li" />
        <SkillLevelListItem
          icon={<SkateboardingIcon />}
          level={5}
          title="Expert"
          description="One of the top most experienced and confident in Kapacity within the skill area. Can handle the most complex situations, be it tech- , people-, process-related (depending on the skill area in question)."
        />
      </List>
    </Dialog>
  );
}

interface SkillLevelListItemProps {
  icon: React.ReactNode;
  level: number;
  title: string;
  description: string;
}
function SkillLevelListItem({
  icon,
  level,
  title,
  description,
}: SkillLevelListItemProps) {
  return (
    <ListItem>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box flex="0 0 5%" display="flex">
          {level}
        </Box>
        <Box flex="0 0 18%" display="flex">
          {icon}
          <Box ml={1}>
            <Typography fontWeight={700}>{title}</Typography>
          </Box>
        </Box>
        <Box flex="1 1 75%">
          <Typography>{description}</Typography>
        </Box>
      </Box>
    </ListItem>
  );
}
