import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { DisplaySkill, EmployeeSkill } from "../../../api";
import { DisplayItem, SkillLevelsDialog } from "../../display";
import { OneToFiveNumberSelector } from "../../input";
import { CommentBox } from "./CommentBox";
import { UserAction } from "./types";
import InfoIcon from "@mui/icons-material/Info";

interface EmployeeSkillEditorProps {
  skill?: DisplaySkill;
  employeeSkill?: EmployeeSkill;
  lastAction?: UserAction;
  setLastAction: (action?: UserAction) => void;
  setEditingSkillId: (skillId?: number) => void;
  setEmployeeSkill: (skill: EmployeeSkill) => Promise<boolean>;
  removeEmployeeSkill: (skillId: number) => Promise<boolean>;
  employeeInitials: string;
  actingAs?: string;
  currentLevel?: number;
  plannedLevel?: number;
  setCurrentLevel: (level?: number) => void;
  setPlannedLevel: (level?: number) => void;
}

export const EmployeeSkillEditor = ({
  skill,
  employeeSkill,
  currentLevel,
  setCurrentLevel,
  plannedLevel,
  setPlannedLevel,
  setEditingSkillId,
  setLastAction,
  employeeInitials,
  actingAs,
  setEmployeeSkill,
  removeEmployeeSkill,
  lastAction,
}: EmployeeSkillEditorProps) => {
  const [showSkillLevels, setShowSkillLevels] = useState(false);
  const onSkillRemoved = async (skillId: number) => {
    const success = await removeEmployeeSkill(skillId);
    if (!success) return; // We should display an error message in this case
    setCurrentLevel(undefined);
    setPlannedLevel(undefined);
    setEditingSkillId(undefined);
    setLastAction({
      skillId,
      type: "Remove",
    });
  };
  return (
    <Box p={2}>
      {!skill && (!lastAction || lastAction.type === "Cancel") && (
        <Box
          width="100%"
          minHeight={200}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6">No skill selected</Typography>
        </Box>
      )}
      {!skill && lastAction && lastAction.type !== "Cancel" && (
        <CommentBox
          lastAction={lastAction}
          showDoge={lastAction.skillLevel === 5}
        />
      )}
      {skill && (
        <>
          <Box width={400}>
            <Typography
              variant="h5"
              mb={2}
              noWrap
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {skill?.name ?? "None selected"}
            </Typography>
          </Box>
          <Box my={4}>
            <Grid container spacing={2}>
              <SkillMetaDataItems skill={skill} />
              {actingAs ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <DisplayItem
                      label="Current level"
                      value={currentLevel ?? "None"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DisplayItem
                      label="Planned level"
                      value={plannedLevel ?? "None"}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <OneToFiveNumberSelector
                      label="Current level"
                      value={currentLevel ?? 0}
                      onChange={setCurrentLevel}
                      includeEmptyMember
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="space-between">
                      <OneToFiveNumberSelector
                        label="Planned level"
                        value={plannedLevel ?? 0}
                        onChange={setPlannedLevel}
                        includeEmptyMember
                      />
                      <IconButton
                        aria-label="skill levels info"
                        onClick={() => setShowSkillLevels(true)}
                      >
                        <InfoIcon />
                      </IconButton>
                      <SkillLevelsDialog
                        open={showSkillLevels}
                        onClose={() => setShowSkillLevels(false)}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {!actingAs && (
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                onClick={() => {
                  setEditingSkillId(undefined);
                  setCurrentLevel(undefined);
                  setPlannedLevel(undefined);
                  setLastAction({ type: "Cancel", skillId: skill.id });
                }}
              >
                Cancel
              </Button>
              {employeeSkill && (
                <Button onClick={() => onSkillRemoved(skill.id)}>Remove</Button>
              )}
              <Button
                onClick={async () => {
                  const updatedEmployeeSkill = {
                    skillId: skill.id,
                    employeeInitials,
                    plannedLevel:
                      plannedLevel === 0 ||
                      plannedLevel === undefined ||
                      plannedLevel < (currentLevel ?? 0)
                        ? undefined
                        : plannedLevel,
                    currentLevel: currentLevel ?? 0,
                  };
                  const success = await setEmployeeSkill(updatedEmployeeSkill);
                  if (!success) return; // We should display an error message in this case

                  if (updatedEmployeeSkill.plannedLevel !== plannedLevel) {
                    setPlannedLevel(updatedEmployeeSkill.plannedLevel ?? 0);
                  }

                  setEditingSkillId(undefined);
                  setLastAction({
                    skillId: skill.id,
                    type: "Save",
                    skillLevel: updatedEmployeeSkill.currentLevel,
                  });
                }}
                disabled={!currentLevel && !plannedLevel}
              >
                Save
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

interface SkillMetaDataProps {
  skill: DisplaySkill;
}

const SkillMetaDataItems = ({ skill }: SkillMetaDataProps) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <DisplayItem label="Team" value={skill.teamNames} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayItem label="Discipline" value={skill.disciplineNames} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayItem label="Tool" value={skill.toolNames} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayItem label="Platform" value={skill.platformNames} />
      </Grid>
      <Grid item xs={12}>
        <DisplayItem label="Skill area" value={skill.skillAreaName} />
      </Grid>
    </>
  );
};
