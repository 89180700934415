import { Box, Typography } from "@mui/material";

interface DisplayItemProps {
  label: string;
  value?: string | number;
}
export const DisplayItem = ({ label, value }: DisplayItemProps) => {
  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column">
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body1">{value ? value : "None"}</Typography>
    </Box>
  );
};
