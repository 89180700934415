import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#94c7ec", //bea069
    },
    secondary: {
      main: "#bea069",
    },
    background: {
      default: "#000",
      paper: "#000",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.4)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      focus: "rgba(255, 255, 255, 0.4)",
    },
  },
  typography: {
    allVariants: {
      color: "#fff",
      fontFamily: "Ubuntu, Helvetica Neue, sans-serif",
    },
  },
});
