import { EmployeeSkill } from ".";
import { getApiBase } from "../getApiBase";

export async function saveEmployeeSkill(
  skill: EmployeeSkill
): Promise<boolean> {
  const url = new URL("/api/employeeSkill/save", getApiBase());
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(skill),
  };

  try {
    const response = await fetch(url.href, options);
    if (response.status >= 400) {
      throw await response.text();
    }

    const result = (await response.json()) as boolean;
    return result;
  } catch (e) {
    return false;
  }
}
