import { getApiBase } from "../getApiBase";
import { Skill } from "../metaData";

interface SkillPriorityInput {
  id: number;
  sortPriority: number;
}
export async function saveSkillPriorities(
  skills: SkillPriorityInput[]
): Promise<Skill[]> {
  const url = new URL("/api/manage/skills/savePriorities", getApiBase());
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(skills),
  };

  try {
    const response = await fetch(url.href, options);
    if (response.status >= 400) {
      throw await response.text();
    }

    const result = (await response.json()) as Skill[];
    return result;
  } catch (e) {
    return [];
  }
}
