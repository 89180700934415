import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { EmployeeSkill } from "../../api";
import { getEmployeeSkills } from "../../api/employeeSkills/getEmployeeSkills";

interface MySkillsStore {
  currentSkills: EmployeeSkill[]; // The skills that are currently added for the employee
  setSkill: (skill: EmployeeSkill) => void;
  removeSkill: (skillId: number) => void;
}

export function useEmployeeSkills(initials?: string | null): MySkillsStore {
  const { isSuccess, isLoading, data } = useQuery(
    ["employeeSkills", initials],
    () => getEmployeeSkills({ initials })
  );
  const [skills, setSkills] = useState<EmployeeSkill[]>([]);
  useEffect(() => {
    if (isLoading || !isSuccess) return;
    setSkills(data ?? []);
  }, [data, isLoading, isSuccess]);

  const setSkill = (skill: EmployeeSkill) => {
    var newSkills = [...skills.filter((s) => s.skillId !== skill.skillId)];
    newSkills.push(skill);
    setSkills(newSkills);
  };

  const removeSkill = (skillId: number) => {
    var newSkills = [...skills.filter((s) => s.skillId !== skillId)];
    setSkills(newSkills);
  };

  return { currentSkills: skills, setSkill, removeSkill };
}
