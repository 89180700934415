import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Discipline,
  Platform,
  saveSkill,
  Skill,
  SkillArea,
  Team,
  Tool,
  useMetaData,
} from "../../api";
import { metaDataQueryKey } from "../../api/metaData/MetaDataContext";
import { deleteSkill } from "../../api/skills/deleteSkill";
import { SaveSkillInput } from "../../api/skills/types";
import {
  TeamsSelector,
  ToolsSelector,
  DisciplinesSelector,
  PlatformsSelector,
  SkillAreaSelector,
} from "../input";
import ConfirmDialog from "../input/ConfirmDialog";
import { NumberInput } from "../input/NumberInput";

interface SkillEditorOptions {
  canPromote?: boolean;
}
interface SkillEditorProps {
  selectedSkill: Skill | null;
  clearSelectedSkill: () => void;
  options: SkillEditorOptions;
}
export const SkillEditor = ({
  selectedSkill,
  clearSelectedSkill,
  options: { canPromote },
}: SkillEditorProps) => {
  const queryClient = useQueryClient();
  const metaData = useMetaData();
  const [teams, setTeams] = useState<Team[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);
  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [sortPriority, setSortPriority] = useState<number>(0);
  const [skillName, setSkillName] = useState<string>("");
  const [skillArea, setSkillArea] = useState<SkillArea | null>(null);
  const [isPromoted, setIsPromoted] = useState<boolean | null>(null);
  const [isLegacy, setIsLegacy] = useState<boolean | null>(null);
  const [statusMessage, setStatusMessage] =
    useState<string>(defaultStatusMessage);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(
    function handleSkillChanged() {
      if (!selectedSkill) {
        return;
      }

      setSkillName(selectedSkill.name);
      setSortPriority(selectedSkill.sortPriority ?? 0);
      setSkillArea(
        metaData.skillAreas.find((s) => s.id === selectedSkill.skillAreaId) ??
          null
      );
      setTeams(
        metaData.teams.filter((t) =>
          selectedSkill.teamLinks.map((tl) => tl.teamId).includes(t.id)
        )
      );
      setTools(
        metaData.tools.filter((t) => selectedSkill.toolIds.includes(t.id))
      );
      setDisciplines(
        metaData.disciplines.filter((d) =>
          selectedSkill.disciplineIds.includes(d.id)
        )
      );
      setPlatforms(
        metaData.platforms.filter((p) =>
          selectedSkill.platformIds.includes(p.id)
        )
      );
      setIsPromoted(selectedSkill.isPromoted);
      setIsLegacy(selectedSkill.isLegacy);
      setStatusMessage(defaultStatusMessage);
    },
    [
      metaData.disciplines,
      metaData.platforms,
      metaData.skillAreas,
      metaData.teams,
      metaData.tools,
      selectedSkill,
    ]
  );

  const handleSaveSkill = async () => {
    const skill = selectedSkill;
    if (!skill || !skillName) return;

    const input: SaveSkillInput = {
      id: skill.id ? skill.id : null,
      name: skillName,
      sortPriority: sortPriority,
      isLegacy: isLegacy,
      isPromoted: isPromoted,
      skillAreaId: skillArea?.id,
      teamLinks: teams.map((t) => ({
        teamId: t.id,
        sortPriority:
          skill.teamLinks.find((tl) => tl.teamId === t.id)?.sortPriority ??
          null,
      })),
      toolIds: tools.map((t) => t.id),
      platformIds: platforms.map((p) => p.id),
      disciplineIds: disciplines.map((d) => d.id),
    };

    const success = await saveSkill(input);
    if (!success) {
      setStatusMessage("Failed saving skill  (╯°□°）╯︵ ┻━┻");
      clearSelectedSkill();
      return;
    }

    queryClient.invalidateQueries(metaDataQueryKey);
    setStatusMessage("Skill saved!  ╰(*°▽°*)╯");
    clearSelectedSkill();
  };

  const handleDeleteSkill = async () => {
    const skill = selectedSkill;
    if (!skill?.id) return;

    const success = await deleteSkill(skill.id);
    if (!success) {
      setStatusMessage("Failed deleting skill  (╯°□°）╯︵ ┻━┻");
      clearSelectedSkill();
      return;
    }

    queryClient.invalidateQueries(metaDataQueryKey);
    setStatusMessage("Skill deleted!  ╰(*°▽°*)╯");
    clearSelectedSkill();
  };

  const handleConfirmDeletion = async () => {
    setIsDeleting(false);
    await handleDeleteSkill();
  };

  return selectedSkill ? (
    <>
      <Box my={2} p={2}>
        <Box mb={4} display="flex" justifyContent="space-between">
          <Typography variant="caption" fontSize="2rem">
            Skill details
            {selectedSkill.id ? "" : " (new skill)"}
          </Typography>
          {selectedSkill.id ? (
            <Box>
              <Button variant="outlined" onClick={() => setIsDeleting(true)}>
                Delete
              </Button>
              <ConfirmDialog
                isOpen={isDeleting}
                onCancel={() => setIsDeleting(false)}
                onConfirm={handleConfirmDeletion}
                description="Please confirm that you wish to delete the selected skill."
              />
            </Box>
          ) : null}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              label="Skill name"
              fullWidth
              size="small"
              value={skillName}
              onChange={(e) => setSkillName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput
              label="Sort priority"
              fullWidth
              onChange={setSortPriority}
              value={sortPriority}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TeamsSelector
              teams={metaData.teams}
              selectedTeams={teams}
              onChange={setTeams}
              label="Select teams"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ToolsSelector
              tools={metaData.tools}
              selectedTools={tools}
              onChange={setTools}
              label="Select tools"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DisciplinesSelector
              disciplines={metaData.disciplines}
              selectedDisciplines={disciplines}
              onChange={setDisciplines}
              label="Select disciplines"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PlatformsSelector
              platforms={metaData.platforms}
              selectedPlatforms={platforms}
              onChange={setPlatforms}
              label="Select platforms"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SkillAreaSelector
              skillAreas={metaData.skillAreas}
              selectedSkillArea={skillArea}
              onChange={setSkillArea}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex">
              {canPromote && (
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ "aria-label": "Promote this skill" }}
                      checked={!!isPromoted}
                      onChange={(_, checked) => setIsPromoted(checked)}
                    />
                  }
                  label="Promote this skill"
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ "aria-label": "Is legacy" }}
                    checked={!!isLegacy}
                    onChange={(_, checked) => setIsLegacy(checked)}
                  />
                }
                label="Is legacy"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Box pr={2}>
          <Button onClick={() => clearSelectedSkill()}>Cancel</Button>
        </Box>
        <Button variant="contained" onClick={handleSaveSkill}>
          Save skill
        </Button>
      </Box>
    </>
  ) : (
    <Box my={2} p={2}>
      <Typography variant="h6">{statusMessage}</Typography>
    </Box>
  );
};

const defaultStatusMessage =
  'No skill selected. Select a skill to edit it, or click "create new skill" to create a new one.';
