import { Typography } from "@mui/material";
import { useEffect } from "react";
import { QueryCache, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { routerPaths } from "./routes/routerPaths";

const Logout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    async function clearCache() {
      await queryClient.invalidateQueries(["me", "metaData"]); // Clear other queries here too?
      const queryCache = new QueryCache();
      // We'll clear the query cache when logging out to ensure that no user data is leaked this way.
      queryCache.clear();

      navigate(routerPaths.home);
    }

    clearCache();
  }, [queryClient, navigate]);

  return <Typography>Logging out...</Typography>;
};

export default Logout;
