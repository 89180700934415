import { Box, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box component="footer" display="flex" justifyContent="center">
      <Box p={2} mt={6}>
        <Typography>Kapacity Skills App</Typography>
        <Typography>© Copyright 2022</Typography>
      </Box>
    </Box>
  );
};
