import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClientPrincipalData } from "../api";
import { routerPaths } from "../pages/routes/routerPaths";
import { UserContext } from "./useCurrentUser";

const dataReaderRole = "datareader";
const whitelistedDomains = ["twoday.com", "kapacityas.onmicrosoft.com"];

// A wrapper component that checks if the user is authorized to read data.
export const ProtectedRoute: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser, isLoading } = useContext(UserContext);
  useEffect(() => {
    if (isLoading || (currentUser && isUserAuthorized(currentUser))) {
      return;
    }

    if (!currentUser) {
      navigate(routerPaths.signIn);
    }

    navigate(routerPaths.accessDenied);
  }, [currentUser, isLoading, navigate]);

  if (isLoading || !currentUser || !isUserAuthorized(currentUser)) {
    return <div>Authorizing user...</div>;
  }

  return <>{children}</>;
};

function isUserAuthorized(user: ClientPrincipalData) {
  // A user may be authorized if they are a datareader or if they are a user with a whitelisted domain.
  if (user.userRoles.includes(dataReaderRole)) {
    return true;
  }

  if (
    whitelistedDomains.some((domain) =>
      user.userDetails.toLowerCase().includes(domain.toLowerCase())
    )
  ) {
    return true;
  }

  return false;
}
