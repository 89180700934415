import { TextField, TextFieldProps } from "@mui/material";

interface NumerInputProps extends Omit<TextFieldProps, "onChange" | "size"> {
  onChange: (value: number) => void;
}

export const NumberInput = ({
  onChange,
  inputProps,
  InputLabelProps,
  ...rest
}: NumerInputProps) => {
  return (
    <TextField
      inputProps={{ ...inputProps, inputMode: "numeric", pattern: "[0-9]*" }}
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      onChange={(e) => onChange(+e.target.value)}
      size="small"
      {...rest}
    />
  );
};
