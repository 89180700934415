import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { Discipline } from "../../api";

interface DisciplinesSelectorProps {
  disciplines: Discipline[];
  selectedDisciplines: Discipline[];
  onChange: (disciplines: Discipline[]) => void;
  label?: string;
}

export const DisciplinesSelector = ({
  disciplines,
  selectedDisciplines,
  onChange,
  label,
}: DisciplinesSelectorProps) => {
  const options = useMemo(
    () => disciplines.sort((a, b) => a.name.localeCompare(b.name)),
    [disciplines]
  );
  return (
    <Autocomplete
      multiple
      id="discipline-filter-input"
      options={options}
      size="small"
      getOptionLabel={(d) => d.name}
      defaultValue={[]}
      onChange={(_, value) => onChange(value)}
      value={selectedDisciplines}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={label ?? "Filter disciplines"} />
      )}
    />
  );
};
