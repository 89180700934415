import { routerPaths } from "../pages/routes/routerPaths";

const appBaseUrl = window.location.origin;
const aadLoginPath = "/.auth/login/aad";
const loginReturnUrlParamName = "post_login_redirect_uri";

export function getSignInFunction(returnUrl: string) {
  // Azure AD
  const aadLoginUrl = new URL("/.auth/login/aad", appBaseUrl);
  aadLoginUrl.searchParams.append(loginReturnUrlParamName, returnUrl);
  const handleSignIn = () => {
    // Redirect to authentication provider
    window.location.href = aadLoginUrl.href;
  };

  return handleSignIn;
}

export function getRelativeSignInUrl() {
  return `${aadLoginPath}?${loginReturnUrlParamName}=${routerPaths.home}`;
}
