import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { EmployeeSkill } from "../../../api";
import { DisplaySkill } from "../../../api";
import { EmployeeSkillEditor } from "./EmployeeSkillEditor";
import { EmployeeSkillList } from "./EmployeeSkillList";
import { UserAction } from "./types";

interface EmployeeSkillOverviewProps {
  skills: DisplaySkill[];
  employeeSkills: EmployeeSkill[];
  setEmployeeSkill: (skill: EmployeeSkill) => Promise<boolean>;
  removeEmployeeSkill: (skillId: number) => Promise<boolean>;
  employeeInitials: string;
  actingAs?: string;
}

export const EmployeeSkillOverview: React.FunctionComponent<
  EmployeeSkillOverviewProps
> = ({ skills, employeeSkills, ...rest }) => {
  // Keep track of the skill that we're editing, and the last user action.
  const [editingSkillId, setEditingSkillId] = useState<number>();
  const [lastAction, setLastAction] = useState<UserAction>();

  // Keep state for the currently selected skill
  const [currentLevel, setCurrentLevel] = useState<number>();
  const [plannedLevel, setPlannedLevel] = useState<number>();

  const handleSetEditingSkillId = (skillId: number | undefined) => {
    if (skillId === undefined) {
      setEditingSkillId(undefined);
      setCurrentLevel(undefined);
      setPlannedLevel(undefined);
      return;
    }

    const employeeSkill = employeeSkills.find((e) => e.skillId === skillId);
    if (employeeSkill === undefined) {
      setEditingSkillId(skillId);
      setCurrentLevel(undefined);
      setPlannedLevel(undefined);
      return;
    }

    setEditingSkillId(skillId);
    setCurrentLevel(employeeSkill.currentLevel);
    setPlannedLevel(employeeSkill.plannedLevel);
  };

  return (
    <Box display="flex" justifyContent="space-between" gap={2}>
      <Box flex="1 1 70%">
        <Paper elevation={10}>
          {skills.length === 0 ? (
            <Box p={2}>
              <Typography variant="h6">
                No skills match the selected filters
              </Typography>
            </Box>
          ) : (
            <EmployeeSkillList
              skills={skills}
              employeeSkills={employeeSkills}
              editingSkillId={editingSkillId}
              setEditingSkillId={handleSetEditingSkillId}
              currentLevel={currentLevel}
              plannedLevel={plannedLevel}
              setCurrentLevel={setCurrentLevel}
              setPlannedLevel={setPlannedLevel}
            />
          )}
        </Paper>
      </Box>
      <Box flex="1 1 30%" position="relative">
        <Box position="sticky" top={0} left={0}>
          <Paper elevation={10}>
            <EmployeeSkillEditor
              skill={skills.find((s) => s.id === editingSkillId)}
              employeeSkill={employeeSkills.find(
                (s) => s.skillId === editingSkillId
              )}
              lastAction={lastAction}
              setLastAction={setLastAction}
              setEditingSkillId={setEditingSkillId}
              currentLevel={currentLevel}
              plannedLevel={plannedLevel}
              setCurrentLevel={setCurrentLevel}
              setPlannedLevel={setPlannedLevel}
              {...rest}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
