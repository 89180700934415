export const routerPaths = {
  signIn: "/sign-in",
  accessDenied: "/access-denied",
  home: "/",
  custom404: "*",
  mySkills: "/my-skills",
  searchEmployees: "/search-employees",
  administration: "/administration",
  teamAdmin: "/team-admin",
  logout: "/logout",
};
