import DoNotDisturb from "@mui/icons-material/DoNotDisturb";
import { Box, Typography } from "@mui/material";

const AccessDenied = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <DoNotDisturb color="secondary" fontSize="large" />
      </Box>
      <Box>
        <Typography>
          It looks like you don't have access to this page.
        </Typography>
      </Box>
    </Box>
  );
};

export default AccessDenied;
