import { Box, Typography, Button } from "@mui/material";
import { DisplaySkill, Skill, Team } from "../../api";
import { SkillSelector } from "../input";

interface SkillInputSelectorProps {
  selectedSkill: Skill | null;
  setSelectedSkill: (s: Skill | null) => void;
  skills: DisplaySkill[];
  team?: Team | null;
}
export const SkillInputSelector = ({
  selectedSkill,
  setSelectedSkill,
  skills,
  team,
}: SkillInputSelectorProps) => {
  const newEmptySkill = team
    ? {
        ...emptySkill,
        teamIds: [team.id],
      }
    : emptySkill;
  return (
    <Box display="flex" justifyContent="space-between">
      <Box p={2} flex="1 1 50%">
        <SkillSelector
          skills={skills}
          selectedSkill={selectedSkill?.id ? selectedSkill : null}
          onChange={setSelectedSkill}
          label="Select existing skill"
        />
      </Box>
      <Box p={2} display="flex" alignItems="center">
        <Typography>or</Typography>
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          onClick={() => setSelectedSkill(newEmptySkill)}
        >
          Create new skill
        </Button>
      </Box>
    </Box>
  );
};

export const emptySkill: Skill = {
  id: 0,
  name: "New skill",
  disciplineIds: [],
  platformIds: [],
  teamLinks: [],
  toolIds: [],
  skillAreaId: undefined,
  sortPriority: 0,
  isPromoted: null,
  isLegacy: null,
};
